<template>
    <div class="latest-card">
        <div class="latest-card-body">
            <p class="latest-card-title fs-5">{{ title }}</p>
            <br v-if="description == null">
            <p class="latest-card-text">{{ description }}</p>
            <a :href="urlLink" class="btn btn-primary my-3" target="_blank">Megtekintés</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CardInfo',
    props: {
        title: String,
        urlLink: String,
        description: String
    }
    
}
</script>