import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

import "./assets/css/style.css";
import "./assets/js/main.js";
import "./assets/js/teszt.js";

createApp(App).use(store).use(router).mount('#app')
