<template>
  <div class="footer">
    <footer class="shadow">
      <div class="container-fluid text-white bg-black">
        <div class="row">
          <div class="col py-2">
            <p class="text-center" id="year">&copy; harmatosbikafing {{ new Date().getFullYear() }}</p>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>