import Axios from "axios";

Axios.defaults.baseURL = 'https://api.github.com/users/';

export default {
    getAllInformations(){
        return Axios.get('/spatrik2001')
            .then(response => { return response.data;})
            .catch(err => { console.log(err)});
    },
    getAllProjects(){
        return Axios.get('/spatrik2001/repos')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getLatestRepositories() {
        return Axios.get('/spatrik2001/repos?sort=pushed')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getAllFollowers(){
        return Axios.get('/spatrik2001/followers')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    },
    getAllFollowing(){
        return Axios.get('/spatrik2001/following')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                console.log(err);
            })
    }
    
}